.auth {
	height: 100vh;
	width: 100%;
	background-color: #fff;
	display: flex;
}

.auth__left {
	height: 100%;
	width: 50%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.auth__left__container {
	height: auto;
	width: 80%;
	display: flex;
	flex-direction: column;
	gap: 2rem;
}

.auth__left__container > img {
	width: 13rem;
	height: 2rem;
	pointer-events: none;
}

.auth__left__container__text {
	margin-top:1%;
}

.auth__left__container__text > p,
h2 {
	margin: 0;
}

.auth__left__container__form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	gap: 0.5rem;
}

.auth__left__container__fdiv {
	display: flex;
	flex-direction: column;
	width: 80%;
}

.fdiv__field1 {
	width: 70%;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.fdiv__field1 > label {
	font-weight: 500;
	margin-bottom: 0.2rem;
	color: #53047b;
}

.fdiv__field1 > span {
	padding: 1px 3px;
	width: max-content;
	font-size: 10px;
	border-radius: 5px;
	background-color: #f7899b;
	color: red;
	font-weight: 500;
}

.fdiv__field2 {
	border-width: 1px;
	border-style: solid;
	padding: 5px;
	padding-left: 5px;
	border-radius: 3px;
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 0.4rem;
	height: 40px;
	width: 100%;
}

.fdiv__field2 > div {
	border-right: 1px solid #b3b3b3;
	padding-right: 3px;
}

.fdiv__field2 > input {
	flex-grow: 1;
	border: 0;
	padding: 0;
	height: 100%;
	width: 100%;
}

.fdiv__field2 > input:focus {
	outline: none;
}

.fdiv__icon {
	font-size: 25px;
	color: #575656;
}

.auth__buttonsection {
	height: auto;
	width: 80%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
}

.auth__buttonsection > div {
	width: 100%;
	display: flex;
	justify-content: center;
	color: red;
}

.auth__buttonsection > small {
	font-size: 14px;
	color: #0071bc;
	margin: 1.2rem 0;
	font-weight: bold;
}

.auth__buttonsection > small p {
	color: #0071bc;
	text-decoration: none;
	margin: 0;
	cursor: pointer;
}

.auth__right {
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.auth__right > img {
	width: 100%;
	height: 100%;
	pointer-events: none;
}

.error_input {
	border-color: #fc2d4f;
}

.normal_input {
	border-color: #b3b3b3;
}
